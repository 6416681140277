import React from 'react';
import './Hero.css';
import arrow from '../../assets/arrow.png';
import { Link } from 'react-router-dom';
import Globe from '../../Components/globe/globe';

const Hero = ({ heroData, setHeroCount, heroCount }) => {
  return (
    <div className="hero">
      <div className="hero-text">{heroData.text1}</div>
      <div className="hero-text">{heroData.text2}</div>
      <div className="hero-subtext">{heroData.subtext}</div>
      <div className="hero-explore">
        <Link to="/Packages" className="hero-explore-link" onClick={() => setHeroCount(heroCount)}>
          <span>Begin Your Transformation</span>
          <img src={arrow} alt="" aria-hidden="true" />
        </Link>
      </div>
      <div className="hero-globe">
        <Globe />
      </div>
      <div className="hero-dots">
        <ul>
          {[0, 1, 2].map((index) => (
            <li
              key={index}
              onClick={() => setHeroCount(index)}
              className={`hero-dot ${heroCount === index ? 'active' : ''}`}
            ></li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Hero;