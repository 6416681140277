

import React, { useEffect, useRef } from 'react';
import { Star } from 'lucide-react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { MdRateReview } from "react-icons/md";
import "./review.css";
gsap.registerPlugin(ScrollTrigger);

const styles = {
  section: `
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  `,
  sectionText: `
    font-size: 15rem;
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 116%;
    transform: translate(-50%, -50%);
    margin: 0;
  `,
  reviewWrapper: `
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    min-height: 0;
    gap: 2rem;
    row-gap: 15rem;
    padding: 0 4rem;
    max-width: 1800px;
    margin: 0 auto;
  `,
  review: `
    width: 100%;
    height: 300px;
    box-shadow: 10px 0px 40px 0px rgba(0,0,0,0.2);
    border-radius: 2rem;
    padding: 3rem;
    box-sizing: border-box;
    z-index: 2;
    background-color: white;
    display: flex;
    flex-direction: column;
  `,
  reviewHead: `
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  `,
  reviewTitle: `
    font-size: 2rem;
    margin: 0;
  `,
  rating: `
    color: orange;
  `,
  content: `
    flex: 1;
    overflow-y: auto;
    font-size: 1rem;
    line-height: 1.5;
    padding-right: 0.5rem;
    margin-top: 1rem;
  `,
  rtitle: `
    font-size: 15rem;
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 20%;
    transform: translate(-50%, -50%);
    margin: 0;
  `
};

const ReviewSection = () => {
  const reviewsRef = useRef(null);
  const titleRef = useRef(null);

  const reviews = [
    // Row 1
    {
      name: "Sarah M.",
      rating: 5,
      content: "Mantra Mountain transformed my life. The journey through the Himalayas wasn't just a trek - it was a profound spiritual awakening."
    },
    {
      name: "David K.",
      rating: 4.8,
      content: "The perfect blend of adventure and inner discovery. Mantra Mountain's expertise helped me reconnect with my true self."
    },
    {
      name: "Emma R.",
      rating: 5,
      content: "From the untouched terrains to the ancient mantras, every moment was carefully curated for maximum impact."
    },
    {
      name: "Michael P.",
      rating: 4.9,
      content: "The partnership with Travel Himalaya Nepal shows in the seamless organization and authentic experiences."
    },
    // Row 2
    {
      name: "Lisa T.",
      rating: 5,
      content: "Found exactly what I was looking for - peace, clarity, and a deeper connection with nature. The spiritual guidance was exceptional."
    },
    {
      name: "James H.",
      rating: 4.9,
      content: "An unforgettable journey that challenged me physically and spiritually. The guides were incredibly knowledgeable."
    },
    {
      name: "Anna K.",
      rating: 5,
      content: "The attention to detail in combining trekking with spiritual practices made this experience truly unique."
    },
    {
      name: "Robert M.",
      rating: 4.8,
      content: "Every step of the journey felt meaningful. The mountain views and spiritual sessions were perfectly balanced."
    },
    // Row 3
    {
      name: "Sophie L.",
      rating: 5,
      content: "The traditional mantras and meditation sessions in the mountains created moments of pure transcendence."
    },
    {
      name: "Thomas B.",
      rating: 4.9,
      content: "A life-changing expedition that helped me find inner peace and physical strength simultaneously."
    },
    {
      name: "Maria G.",
      rating: 5,
      content: "The cultural immersion and spiritual guidance provided by Mantra Mountain was beyond expectations."
    },
    {
      name: "Daniel R.",
      rating: 4.8,
      content: "An expertly curated journey that balanced physical challenge with spiritual growth perfectly."
    },
    // Row 4
    {
      name: "Rachel W.",
      rating: 5,
      content: "The sacred sites and meditation spots along the trek were chosen with deep understanding and respect."
    },
    {
      name: "Kevin P.",
      rating: 4.9,
      content: "This journey helped me disconnect from the mundane and connect with something far more meaningful."
    },
    {
      name: "Laura M.",
      rating: 5,
      content: "The combination of hiking and spiritual practices created a truly transformative experience."
    },
    {
      name: "Chris S.",
      rating: 4.8,
      content: "Every aspect of the journey felt intentional and meaningful. A truly life-changing experience."
    },
    // Row 5
    {
      name: "Nina H.",
      rating: 5,
      content: "The wisdom shared by the guides and the energy of the mountains created profound transformation."
    },
    {
      name: "Paul T.",
      rating: 4.9,
      content: "An incredible blend of adventure, spirituality, and personal growth in the majestic Himalayas."
    },
    {
      name: "Jessica K.",
      rating: 5,
      content: "This journey exceeded all expectations. The spiritual guidance was profound and lasting."
    },
    {
      name: "Mark L.",
      rating: 4.8,
      content: "A perfect balance of physical challenge and spiritual discovery in the world's most majestic setting."
    }
  ];

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".reviews",
        start: "top 40%",
        end: "bottom top",
        scrub: 1,
      }
    });

    // Animate each row differently
    tl.fromTo(".row-1", {y: '350%'}, {y: '-150%'}, 0);
    tl.fromTo(".row-2", {y: '300%'}, {y: '-50%'}, 0);
    tl.fromTo(".row-3", {y: '250%'}, {y: '-100%'}, 0);
    tl.fromTo(".row-4", {y: '200%'}, {y: '-75%'}, 0);
    tl.fromTo(".row-5", {y: '150%'}, {y: '-25%'}, 0);

    gsap.fromTo(".rtitle", 
      {x: '100%'}, 
      {
        x: '-120%',
        scrollTrigger: {
          trigger: ".rtitle",
          start: "center center",
          end: "bottom center",
          endTrigger: ".reviews",
          pin: true,
          scrub: 1,
        }
      },
      0
    );

    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  return (
    <>
   

      <section className="reviews" ref={reviewsRef} style={{ cssText: styles.section }}>
        <p className="rtitle" ref={titleRef} style={{ cssText: styles.rtitle }}>
           <br /> REVIEWS
        </p>
        
        <div className="review-wrp" style={{ cssText: styles.reviewWrapper }}>
          {reviews.map((review, index) => (
            <div
              key={index}
              className={`review row-${Math.floor(index / 4) + 1}`}
              style={{ cssText: styles.review }}
            >
              <div className="rhead" style={{ cssText: styles.reviewHead }}>
                <div className="title" style={{ cssText: styles.reviewTitle }}>
                  {review.name}
                </div>
                <div className="rating" style={{ cssText: styles.rating }}>
                  {[...Array(5)].map((_, i) => (
                    <Star 
                      key={i}
                      className="w-5 h-5" 
                      fill={i < Math.floor(review.rating) ? "orange" : "none"}
                      color="orange"
                    />
                  ))}
                </div>
              </div>
              <div className="content" style={{ marginTop: '1rem' }}>
                "{review.content}"
              </div>
            </div>
          ))}
        </div>
      </section>

      <section style={{ cssText: styles.section }}>
        <p style={{ }}><h1><u><MdRateReview />  </u> </h1>  </p>
      </section>  
       {/* <section className="s1" style={{ cssText: styles.section + 'border-bottom: 1rem solid black;' }}>
        <p style={{ cssText: styles.sectionText }}>   </p>
      </section> */}
    </>
  );
};

export default ReviewSection;
//p