import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Loader2, MapPin, Globe, Calendar, Map, CheckCircle, XCircle } from 'lucide-react';
import axios from 'axios';
import './PackageDetail.css';
import CampaignSection from '../campaign/CampaignSection';


const PackageDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [packageData, setPackageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchPackage = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`https://nodejs-serverless-function-express-henna-theta.vercel.app/api/packages/slug/${slug}`);
        setPackageData(response.data);
        if (response.data.overallImages.length > 0) {
          setSelectedImage(response.data.overallImages[0]);
        }
      } catch (error) {
        console.error('Error fetching package:', error);
        setError(error.response?.data?.message || 'Failed to load package details');
      } finally {
        setLoading(false);
      }
    };
  
    if (slug) {
      fetchPackage();
    }
  }, [slug]);
  

  if (loading) {
    return (
      <div className="loading-container">
        <Loader2 className="animate-spin" size={48} />
        <p>Loading package details...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <h3>Error Loading Package</h3>
        <p>{error}</p>
        <button onClick={() => navigate(-1)}>Go Back</button>
      </div>
    );
  }

  if (!packageData) {
    return (
      <div className="error-container">
        <h3>Package Not Found</h3>
        <p>The requested package could not be found.</p>
        <button onClick={() => navigate(-1)}>Go Back</button>
      </div>
    );
  }

  return (
    <div className="package-detail-container">
      <header className="package-header">
        <h1>{packageData.title}</h1>
        <div className="package-meta">
          <Calendar /> <span>{packageData.duration}</span>
          <span>•</span>
          <MapPin /> <span>{packageData.difficulty}</span>
          <span>•</span>
          <span>${packageData.price}</span>
        </div>
      </header>

      <section className="image-showcase">
        <div className="main-image">
          <img src={selectedImage || packageData.overallImages[0]} alt={packageData.title} />
        </div>
        <div className="image-gallery">
          {packageData.overallImages.map((img, index) => (
            <div 
              key={index} 
              className={`gallery-thumbnail ${selectedImage === img ? 'active' : ''}`}
              onClick={() => setSelectedImage(img)}
            >
              <img src={img} alt={`View ${index + 1}`} />
            </div>
          ))}
        </div>
      </section>

      <div className="content-grid">
        <section className="main-content">
          <div className="description">
            <h2>Overview</h2>
            <p>{packageData.description}</p>
          </div>

          <div className="itinerary">
            <h2>Daily Itinerary</h2>
            {packageData.itinerary.map((day, index) => (
              <div key={index} className="itinerary-day">
                <div className="day-header">
                  <h3>Day {day.day}: {day.title}</h3>
                  <div className="day-stats">
                    <Map /> <span>Distance: {day.distance}</span>
                    <Calendar /> <span>Duration: {day.duration}</span>
                    <span>Ascent: {day.ascent}</span>
                    <span>Descent: {day.descent}</span>
                  </div>
                </div>
                <p>{day.description}</p>
                {day.imageUrl && (
                  <img 
                    src={day.imageUrl} 
                    alt={`Day ${day.day} - ${day.title}`} 
                    className="day-image"
                  />
                )}
              </div>
            ))}
          </div>
        </section>

        <aside className="side-content">
          <div className="quick-info">
            <h3>Trip Details</h3>
            <div className="info-grid">
              <div>
                <strong><MapPin /> Region</strong>
                <p>{packageData.region}</p>
              </div>
              <div>
                <strong><Globe /> Country</strong>
                <p>{packageData.country}</p>
              </div>
              <div>
                <strong><Map /> Daily Distance</strong>
                <p>{packageData.dailyDistance}</p>
              </div>
              <div>
                <strong>Daily Ascent</strong>
                <p>{packageData.dailyAscent}</p>
              </div>
            </div>
          </div>

          <div className="inclusions">
            <h3><CheckCircle /> What's Included</h3>
            <ul>
              {packageData.included.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>

          <div className="exclusions">
            <h3><XCircle /> What's Not Included</h3>
            <ul>
              {packageData.excluded.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </aside>
        <CampaignSection/>
      </div>
    </div>
  );
};

export default PackageDetail;
