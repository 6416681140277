import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './PackageList.css';
import Confetti from 'react-dom-confetti';
import { FaGlobe, FaMapMarkerAlt, FaWalking, FaMountain, FaListAlt } from 'react-icons/fa';
import { LiaCalendarDaySolid } from "react-icons/lia";
import BookingCalendar from '../bookingCalendar/BookingCalendar';
import Payment from '../Payment/Payment';
import CampaignSection from '../campaign/CampaignSection';

const PackageList = () => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/packages');
        if (Array.isArray(response.data)) {
          setPackages(response.data);
        } else {
          throw new Error('Invalid data format');
        }
      } catch (error) {
        console.error('Error fetching packages:', error);
        setError(error.response?.data?.message || 'Failed to load packages');
      } finally {
        setLoading(false);
      }
    };
    fetchPackages();
  }, []);

  // Updated to use slug instead of id
  const handlePackageClick = (slug) => {
    navigate(`/packages/${slug}`);
  };

  const handleBookNow = (pkg) => {
    setSelectedPackage(pkg);
  };

  const handleCloseBooking = () => {
    setSelectedPackage(null);
  };

  const handleBooking = (date, formData) => {
    setBookingDetails({ packageData: selectedPackage, date, formData });
    setIsPaymentOpen(true);
  };

  const handlePaymentSuccess = () => {
    setShowConfetti(true);
    setTimeout(() => setShowConfetti(false), 3000);
    setIsPaymentOpen(false);
    setSelectedPackage(null);
  };

  const handlePaymentCancel = () => {
    setIsPaymentOpen(false);
    setSelectedPackage(null);
  };

  const confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  if (loading) return <p>Loading packages...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="package-page">
      <h2 className="text-center">All Packages</h2>
      {showConfetti && <Confetti active={showConfetti} config={confettiConfig} />}
      <div className="package-grid">
        {packages.map((pkg) => (
          <div
            key={pkg._id}
            className="package-card"
            onClick={() => handlePackageClick(pkg.slug)} // Updated to use slug
          >
            <div className="package-card-inner">
              {/* Front of the card */}
              <div className="package-front">
                <div className="package-image-container">
                  <img src={pkg.overallImages[0]} alt={pkg.title} className="package-image" />
                  <div className="package-overlay">
                    <span className="difficulty-badge">{pkg.difficulty}</span>
                  </div>
                </div>
                <div className="package-details">
                  <h2>{pkg.title}</h2>
                  <div className="trip-details">
                    <div className="detail-item"><FaGlobe className="detail-icon" />{pkg.country}</div>
                    <div className="detail-item"><FaMapMarkerAlt className="detail-icon" />{pkg.region}</div>
                    <div className="detail-item"><FaWalking className="detail-icon" />{pkg.dailyDistance}</div>
                    <div className="detail-item"><FaMountain className="detail-icon" />{pkg.dailyAscent}</div>
                    <div className="detail-item"><FaMountain className="detail-icon" />{pkg.dailyDescent}</div>
                    <div className="detail-item"><LiaCalendarDaySolid className="detail-icon"/>{pkg.duration}</div>
                  </div>
                  <h3 className="price">${pkg.price}</h3>
                  <button 
                    className="book-now" 
                    onClick={(e) => { 
                      e.stopPropagation(); 
                      handleBookNow(pkg); 
                    }}
                  >
                    Book Now
                  </button>
                </div>
              </div>
              
              {/* Back of the card */}
              <div className="package-back">
                <h3>Trip Description</h3>
                <p className="back-description">{pkg.description}</p>
                <h3>Itinerary</h3>
                <ul>
                  {pkg.itinerary.map((item, index) => (
                    <li key={index}>
                      <FaListAlt className="detail-icon" />
                      {item.title}
                    </li>
                  ))}
                </ul>
                <button 
                  className="book-now back-button" 
                  onClick={(e) => { 
                    e.stopPropagation(); 
                    handleBookNow(pkg); 
                  }}
                >
                  Book Now
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {selectedPackage && (
        <BookingCalendar 
          packageData={selectedPackage} 
          onClose={handleCloseBooking} 
          onBook={handleBooking}
        />
      )}

      {isPaymentOpen && (
        <Payment 
          amount={bookingDetails.packageData.price * 0.3}
          onPaymentSuccess={handlePaymentSuccess}
          onPaymentCancel={handlePaymentCancel}
        />
      )}
      <CampaignSection/>
    </div>
  );
};

export default PackageList;
