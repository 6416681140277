import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import logo from '../../assets/logo.jpg';

export const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
      <Link to="/" className="navbar-brand">
        <img src={logo} alt="Mantra Mountain Logo" className="navbar-logo" />
        <span className="navbar-name">Mantra Mountain</span>
      </Link>
      <div className="navbar-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={`navbar-menu ${isMenuOpen ? 'open' : ''}`}>
    <li><Link to="/" className={location.pathname === '/' ? 'active' : ''}>Home</Link></li>
    <li><Link to="/Packages" className={location.pathname === '/Package' ? 'active' : ''}>Package</Link></li>
    <li><Link to="/blog" className={location.pathname === '/blog' ? 'active' : ''}>Blog</Link></li> {/* Updated Blog Link */}
    <li><Link to="/About" className={location.pathname === '/About' ? 'active' : ''}>About</Link></li>
    <li className='navbar-contact'><Link to="/Contact" className={location.pathname === '/Contact' ? 'active' : ''}>Contact</Link></li>
</ul>
    </nav>
  );
};

export default Navbar;