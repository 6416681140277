import React, { useState, useEffect } from 'react';
import { Navigation, EffectCube, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import './Swiper.css';

// Import the package data
import { packageData } from '../PackageData/PackageData'; // Adjust the path as necessary

// Assuming these components exist in your project
import BookingCalendar from '../bookingCalendar/BookingCalendar';
import Payment from '../Payment/Payment';

const SwiperComponent = () => {
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);

  useEffect(() => {
    setPackages(packageData);
  }, []);

  const handleBookNow = (packageData) => {
    setSelectedPackage(packageData);
  };

  const handleCloseBooking = () => {
    setSelectedPackage(null);
  };

  const handleBooking = (packageData, date, formData) => {
    setBookingDetails({ packageData, date, formData });
    setIsPaymentOpen(true);
  };

  const handlePaymentSuccess = async () => {
    alert('Booking confirmed! Get ready for your adventure!');
    setIsPaymentOpen(false);
    setSelectedPackage(null);
  };

  const handlePaymentCancel = () => {
    setIsPaymentOpen(false);
    setSelectedPackage(null);
  };

  return (
    <section id="packages">
      {selectedPackage && (
        <BookingCalendar 
          packageData={selectedPackage} 
          onClose={handleCloseBooking}
          onBook={handleBooking}
        />
      )}
      {isPaymentOpen && (
        <Payment 
          amount={bookingDetails.packageData.price * 0.25} 
          onPaymentSuccess={handlePaymentSuccess}
          onPaymentCancel={handlePaymentCancel}
        />
      )}
      <div className="container">
        <h3 className="section-subheading">- Transformative Journeys Await -</h3>
        <h1 className="section-heading">Discover Your True Self in Nepal's Majestic Himalayas</h1>
      </div>
      <div className="container">
        <Swiper
          modules={[Navigation, EffectCube, Pagination]}
          effect={'cube'}
          cubeEffect={{
            shadow: true,
            slideShadows: true,
            shadowOffset: 20,
            shadowScale: 0.94,
          }}
          slidesPerView={1}
          navigation={true}
          pagination={{ clickable: true }}
          loop={true}
          className="mySwiper"
        >
          {packages.map((packageData) => (
            <SwiperSlide key={packageData.id}>
              <div className="package-card">
                <div className="package-image">
                  <img src={packageData.imageUrl} alt={packageData.packageName} />
                  <div className="package-overlay">
                    <span className="experience-badge">{packageData.difficulty}</span>
                  </div>
                </div>
                <div className="package-card-content">
                  <h2>{packageData.packageName}</h2>
                  <p className="emotional-description">{packageData.description}</p>
                  <div className="package-benefits">
                    <h4>Transform Your Life:</h4>
                    <ul>
                      {packageData.highlights.map((benefit, index) => (
                        <li key={index}>{benefit}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="package-details">
                    <span className="duration"><i className="fas fa-clock"></i> {packageData.duration} Days of Discovery</span>
                    <span className="elevation"><i className="fas fa-mountain"></i> Peak at {packageData.maxElevation}</span>
                  </div>
                  <div className="package-footer">
                    <h3 className="value-proposition">Lifelong Memories from ${packageData.price}</h3>
                    <button className="embark-journey" onClick={() => handleBookNow(packageData)}>Embark on Your Journey</button>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default SwiperComponent;
