import him from '../../assets/him.jpg';
import mmm from '../../assets/mmm.jpg';
import langtang from '../../assets/langtang.jpg';
import ann from '../../assets/ann.jpeg';
import '../Swiper/Swiper.css';
import upper from '../../assets/upper-mustang.jpg';
import gokyo from '../../assets/Gokyo-lake-trek.webp';
import annapurna from '../../assets/annapurna_base_camp.jpg';
import Kanchenjunga from '../../assets/Kanchenjunga.jpg';
import MountMakalu from '../../assets/Mount-Makalu.jpg';
import dhaulagiri from '../../assets/dhaulagiri.jpg';
import poonHill from '../../assets/poonHill.webp';
import MardiHimal from '../../assets/mardiHimal.jpg';
import NarPhu from '../../assets/NarPhu.webp';
import lowerDolpa from '../../assets/lowerDolpa.jpeg';
import tsumvalley from '../../assets/tsumvalley.jpg';
import raraLake from '../../assets/raraLake.jpg';
import threePasses from '../../assets/threePasses.jpg';
import annapurnaPanorama from '../../assets/annapurnaPanorama.jpg';
import tamangHeritageLangtang from '../../assets/tamangHeritageLangtang.jpg';


export const packageData = [
    {
      id: 1,
      imageUrl: him,
      packageName: 'Everest Base Camp Trek',
      duration: 14,
      difficulty: 'Challenging',
      maxElevation: '5,545m',
      price: 2500,
      description: 'Stand at the foot of the world\'s highest peak on this iconic trek.',
      highlights: ['Panoramic views from Kala Patthar', 'Sherpa culture', 'Stunning Himalayan scenery']
    },
    {
      id: 2,
      imageUrl: ann,
      packageName: 'Annapurna Circuit',
      duration: 21,
      difficulty: 'Strenuous',
      maxElevation: '5,416m',
      price: 3000,
      description: 'Circle the Annapurna massif on this diverse and challenging trek.',
      highlights: ['Thorong La Pass', 'Diverse landscapes', 'Traditional villages']
    },
    {
      id: 3,
      imageUrl: langtang,
      packageName: 'Langtang Valley Trek',
      duration: 10,
      difficulty: 'Moderate',
      maxElevation: '4,984m',
      price: 1800,
      description: 'Explore the beautiful "valley of glaciers" on this shorter trek.',
      highlights: ['Kyanjin Gompa', 'Yak cheese factories', 'Tserko Ri viewpoint']
    },
    {
      id: 4,
      imageUrl: mmm,
      packageName: 'Manaslu Circuit Trek',
      duration: 16,
      difficulty: 'Challenging',
      maxElevation: '5,160m',
      price: 2200,
      description: 'Trek around the world\'s 8th highest peak on less crowded trails.',
      highlights: ['Larkya La Pass', 'Remote villages', 'Diverse ecosystems']
    },
    {
      id: 5,
      imageUrl:upper,
      packageName: 'Upper Mustang Trek',
      duration: 14,
      difficulty: 'Moderate',
      maxElevation: '3,810m',
      price: 3000,
      description: 'Discover the hidden kingdom of Lo in this unique cultural trek.',
      highlights: ['Ancient monasteries', 'Tibetan culture', 'Dramatic landscapes']
    },
    {
      id: 6,
      imageUrl: gokyo,
      packageName: 'Gokyo Lakes Trek',
      duration: 12,
      difficulty: 'Challenging',
      maxElevation: '5,357m',
      price: 2300,
      description: 'Visit the stunning turquoise lakes of the Gokyo Valley.',
      highlights: ['Gokyo Ri viewpoint', 'Ngozumpa Glacier', 'Cho La Pass']
    },
    {
      id: 7,
      imageUrl: annapurna,
      packageName: 'Annapurna Base Camp Trek',
      duration: 11,
      difficulty: 'Moderate',
      maxElevation: '4,130m',
      price: 1900,
      description: 'Trek to the heart of the Annapurna Sanctuary.',
      highlights: ['Machhapuchhre Base Camp', 'Hot springs', 'Rhododendron forests']
    },
    {
      id: 8,
      imageUrl: Kanchenjunga,
      packageName: 'Kanchenjunga Base Camp Trek',
      duration: 24,
      difficulty: 'Strenuous',
      maxElevation: '5,143m',
      price: 3500,
      description: 'Trek to the base of the world\'s third-highest mountain.',
      highlights: ['Remote wilderness', 'Diverse flora and fauna', 'Spectacular mountain views']
    },
    {
      id: 9,
      imageUrl: MountMakalu,
      packageName: 'Makalu Base Camp Trek',
      duration: 22,
      difficulty: 'Strenuous',
      maxElevation: '5,250m',
      price: 3200,
      description: 'Experience the raw beauty of the Makalu-Barun National Park.',
      highlights: ['Diverse ecosystems', 'Sherpa and Rai culture', 'Off-the-beaten-path']
    },
    {
      id: 10,
      imageUrl: dhaulagiri,
      packageName: 'Dhaulagiri Circuit Trek',
      duration: 18,
      difficulty: 'Very Strenuous',
      maxElevation: '5,360m',
      price: 3300,
      description: 'Circle the mighty Dhaulagiri massif on this challenging trek.',
      highlights: ['French Pass', 'Dhaulagiri Icefall', 'Hidden Valley']
    },
    {
      id: 11,
      imageUrl: poonHill,
      packageName: 'Poon Hill Trek',
      duration: 5,
      difficulty: 'Easy',
      maxElevation: '3,210m',
      price: 800,
      description: 'A short trek with stunning views of the Annapurna range.',
      highlights: ['Sunrise view from Poon Hill', 'Gurung villages', 'Rhododendron forests']
    },
    {
      id: 12,
      imageUrl: MardiHimal,
      packageName: 'Mardi Himal Trek',
      duration: 8,
      difficulty: 'Moderate',
      maxElevation: '4,500m',
      price: 1200,
      description: 'An off-the-beaten-path trek in the Annapurna region.',
      highlights: ['Panoramic views of Annapurna South', 'Less crowded trails', 'Tea house accommodation']
    },
    {
      id: 13,
      imageUrl: NarPhu,
      packageName: 'Nar Phu Valley Trek',
      duration: 14,
      difficulty: 'Challenging',
      maxElevation: '5,320m',
      price: 2800,
      description: 'Explore the hidden valleys of Nar and Phu.',
      highlights: ['Medieval Tibetan culture', 'Remote villages', 'Kang La Pass']
    },
    {
      id: 14,
      imageUrl: lowerDolpa,
      packageName: 'Lower Dolpo Trek',
      duration: 20,
      difficulty: 'Strenuous',
      maxElevation: '5,360m',
      price: 3600,
      description: 'Trek through the remote and culturally rich Dolpo region.',
      highlights: ['Shey Phoksundo Lake', 'Ancient Buddhist monasteries', 'Numa La Pass']
    },
    {
      id: 15,
      imageUrl: tsumvalley,
      packageName: 'Tsum Valley Trek',
      duration: 16,
      difficulty: 'Moderate',
      maxElevation: '3,700m',
      price: 2400,
      description: 'Discover the hidden Tsum Valley, a sacred Buddhist pilgrimage site.',
      highlights: ['Mu Gompa', 'Traditional Tibetan culture', 'Stunning mountain scenery']
    },
    {
      id: 16,
      imageUrl: raraLake,
      packageName: 'Rara Lake Trek',
      duration: 10,
      difficulty: 'Moderate',
      maxElevation: '3,060m',
      price: 1600,
      description: 'Trek to the largest lake in Nepal, located in the remote west.',
      highlights: ['Pristine Rara Lake', 'Diverse wildlife', 'Remote western Nepal']
    },
    {
      id: 17,
      imageUrl: threePasses,
      packageName: 'Three Passes Trek',
      duration: 19,
      difficulty: 'Very Strenuous',
      maxElevation: '5,535m',
      price: 3200,
      description: 'The ultimate Everest region trek crossing three high passes.',
      highlights: ['Kongma La, Cho La, and Renjo La passes', 'Everest Base Camp', 'Gokyo Lakes']
    },
    {
      id: 18,
      imageUrl: Kanchenjunga,
      packageName: 'Kanchenjunga Circuit',
      duration: 28,
      difficulty: 'Very Strenuous',
      maxElevation: '5,143m',
      price: 3800,
      description: 'A challenging circuit around the world\'s third-highest peak.',
      highlights: ['North and South Base Camps', 'Diverse cultures', 'Pristine wilderness']
    },
    {
      id: 19,
      imageUrl: annapurnaPanorama,
      packageName: 'Annapurna Panorama Trek',
      duration: 7,
      difficulty: 'Easy to Moderate',
      maxElevation: '3,210m',
      price: 1100,
      description: 'A shorter trek with fantastic views of the Annapurna range.',
      highlights: ['Poon Hill sunrise', 'Cultural experiences', 'Comfortable tea houses']
    },
    {
      id: 20,
      imageUrl: tamangHeritageLangtang,
      packageName: 'Tamang Heritage Trail',
      duration: 9,
      difficulty: 'Moderate',
      maxElevation: '3,165m',
      price: 1400,
      description: 'Explore the rich culture of the Tamang people in Langtang region.',
      highlights: ['Traditional Tamang villages', 'Hot springs', 'Mountain views']
    }
  ];
