import React from 'react';
import Marquee from 'react-fast-marquee';
import ntv from '../../assets/ntb.png';
import snvLogo from '../../assets/ocrLogo.png';
// import ntv from '../../assets/ntb.jpg';
import wwf from '../../assets/wwf.png';
// import visitNepal from '../../assets/visitNepal.png';
import visitN from '../../assets/visitNepal.png';
import NATTHM from '../../assets/NATTHM.png';


const MarqueeComponent = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Our Technology Partners</h1>
      <Marquee
        pauseOnHover={true}       // Pauses when hovering over the marquee
        direction="left"          // Changes the direction of the scroll
        speed={50}                // Sets the scroll speed
        gradient={false}          // Disables gradient effect at edges
      >
        {/* Replace these with your own images or text */}
        <img src={snvLogo} alt="Partner 1" style={styles.image} />
        <img src={ntv} alt="Partner 2" style={styles.image} />
        <img src={NATTHM} alt="Partner 3" style={styles.image} />
        <img src={wwf} alt="Partner 4" style={styles.image} />
        <img src={visitN} alt="Partner 5" style={styles.image} />
        
      </Marquee>
    </div>
  );
};

const styles = {
  container: {
    padding: '50px 0',
    borderRadius: '10px',
    textAlign: 'center',
    maxWidth: '1020px',
    margin: '0 auto',
  },
  heading: {
    fontSize: '1.8em',
    color: '#333',
    marginBottom: '20px',
  },
  image: {
    width: '150px',             // Fixed width for images
    height: 'auto',              // Automatic height to maintain aspect ratio
    margin: '0 20px',
    borderRadius: '8px',
    // border: '2px solid #ddd',
    padding: '5px',
    transition: 'transform 0.3s', // Hover effect for images
  },
};

export default MarqueeComponent;

