import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
// import Package from "./pages/Package";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import About from "./pages/About";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import BlogList from '../src/Components/BlogPost/BlogList';
import CreateBlog from '../src/Components/admin/CreateBlog';
import BlogPost from '../src/Components/BlogPost/BlogPost';
import PackageUpload from '../src/Components/admin/PackageUpload';
import PackageDetail from "./Components/PackageDetail/PackageDetail";
import PackageList from '../src/Components/PackageList/PackageList';
// import EditPackage from "./Components/EditPackage/EditPackage";
import AdminDashboard from "./Components/admin/AdminDashboard";
import AdminLogin from "./Components/admin/AdminLogin"; 
import AdminLayout from "./Components/admin/AdminLayout";


const App = () => {
  return (
    <BrowserRouter>
      <div>
        <Navbar />
       
     <Routes>
  <Route path="/" element={<Home />} />
  <Route path="/Contact" element={<Contact />} />
  <Route path="/About" element={<About />} />
  <Route path="/CreateBlog" element={<CreateBlog />} />
  <Route path="/blog" element={<BlogList />} />
  <Route path="/blog/:slug" element={<BlogPost />} />
  <Route path="/PackageUpload" element={<PackageUpload />} />
  <Route path="/packages/:slug" element={<PackageDetail />} />
  <Route path="/packages" element={<PackageList />} />

  {/* Admin Routes */}
  <Route path="/admin/login" element={<AdminLogin />} />
  <Route path="/admin/*" element={<AdminLayout />}>
    <Route index element={<AdminDashboard />} />
  </Route>
</Routes>

        
      </div>
    </BrowserRouter>
  );
};

export default App;
