import React, { useState } from 'react';
import Confetti from 'react-dom-confetti';
import './Payment.css';

const Payment = ({ amount, onPaymentSuccess, onPaymentCancel, bookingDetails }) => {
  const [showConfetti, setShowConfetti] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(true); // Track modal visibility

  // Function to create a new user
  // const createUser = async (userDetails) => {
  //   try {
  //     const response = await fetch('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/users', { 
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(userDetails),
  //     });
      
  
  //     if (!response.ok) {
  //       const errorData = await response.json();
  //       console.error('Error response from createUser API:', errorData);
  //       throw new Error('Failed to create user');
  //     }
  
  //     const user = await response.json();
  //     console.log('User created successfully:', user);
  //     return user;
  //   } catch (error) {
  //     console.error('Error creating user:', error);
  //     setError('Error creating user. Please try again.');
  //     throw error;
  //   }
  // };
  
// Handle payment logic
const handlePayment = async () => {
  setIsLoading(true);
  setError(null);

  const { email, contactNumber } = bookingDetails.formData;

  try {
    // Create the user before payment
    await fetch('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/users', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        phoneNumber: contactNumber, // Ensure this matches the backend field name
        role: 'user', // Default role
      }),
    });

    // Proceed with booking request
    const response = await fetch('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/book', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        packageData: bookingDetails.packageData, // Ensure correct format
        date: bookingDetails.date,
        formData: bookingDetails.formData,
        numberOfPeople: bookingDetails.numberOfPeople,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Error response from book API:', errorData);
      throw new Error('Payment failed');
    }

    setShowConfetti(true);
    setTimeout(() => {
      setShowConfetti(false);
      setIsModalOpen(false); // Close the modal after 3 seconds
      onPaymentSuccess();
    }, 3000);
  } catch (err) {
    setError('Payment failed. Please try again.');
    console.error('Error during payment process:', err);
  } finally {
    setIsLoading(false);
  }
};

  

  // Configuration for confetti animation
  const confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: '10px',
    height: '10px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  };

  if (!isModalOpen) {
    return null; // Do not render anything if the modal is closed
  }

  return (
    <div className="payment-modal">
      <div className="payment-content">
        <h2>Pay ${amount}</h2>
        <p>
          Once you receive the confirmation email, which will include the available dates and payment details, 
          you are required to pay 30% of the total amount as an advance deposit. This payment should be made 
          immediately upon receiving the confirmation email. A day or two before the trek begins, we will 
          schedule a briefing session to provide you with all the necessary details about the trek. During 
          this session, you will meet your guide and fellow trekkers. The briefing will take place in a 
          designated hotel hall, where we will also serve breakfast. At this time, the remaining balance of 
          the trek fee must be paid in full. During the briefing, we will cover important information such 
          as what to bring, safety measures, and other essential guidelines to ensure a successful and 
          enjoyable trek.
        </p>
        {error && <p className="error">{error}</p>}
        <button onClick={handlePayment} disabled={isLoading}>
          {isLoading ? 'Processing...' : 'Okay I understand'}
        </button>
        <button onClick={onPaymentCancel} disabled={isLoading}>Cancel</button>
      </div>
      <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <Confetti active={showConfetti} config={confettiConfig} />
      </div>
    </div>
  );
};

export default Payment;
