import React, { useState } from 'react';
import Confetti from 'react-dom-confetti';
import '../pages/pagesCss/Contact.css';
import ChatBot from '../Components/ChatBot/ChatBot';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    messageTitle: '',
    message: ''
  });
  const [status, setStatus] = useState('');
  const [showConfetti, setShowConfetti] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setError(''); // Clear any previous errors
  };

  const validateForm = () => {
    if (!formData.name.trim()) return 'Name is required';
    if (!formData.email.trim()) return 'Email is required';
    if (!formData.phoneNumber.trim()) return 'Phone number is required';
    if (!formData.messageTitle) return 'Message title is required';
    if (!formData.message.trim()) return 'Message is required';
    return null;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setStatus('Sending message...');
    setError('');
  
    try {
      const response = await fetch('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });
  
      const data = await response.json();
  
      if (data.success) {
        setStatus('Message sent successfully!');
        setShowConfetti(true);
        setFormData({
          name: '',
          email: '',
          phoneNumber: '',
          messageTitle: '',
          message: ''
        });
      } else {
        setError(data.message || 'Failed to send message');
      }
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to send message. Please try again.');
    } finally {
      setTimeout(() => {
        setStatus('');
        setShowConfetti(false);
      }, 5000);
    }
  };
  
  
  const confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 5000,
    stagger: 3,
    width: "10px",
    height: "10px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
  };

  const messageTitles = [
    'General Inquiry',
    'Booking Details',
    'Guide Application',
    'Feedback',
    'Suggestion',
    'Reservation',
    'Meetup Request',
    'Other'
  ];

  return (
    <div className="contact-page">
      <h1 className="text-center">Contact Us</h1>
      
      {status && <p className="status-message success">{status}</p>}
      {error && <p className="status-message error">{error}</p>}
      
      <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <Confetti active={showConfetti} config={confettiConfig} />
      </div>
      
      <form className="contact-form" onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name *</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            placeholder="Enter your name"
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email *</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            placeholder="Enter your email"
          />
        </div>

        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number *</label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
            placeholder="Enter your phone number"
            pattern="[0-9+\-\s()]+"
          />
        </div>

        <div className="form-group">
          <label htmlFor="messageTitle">Message Title *</label>
          <select
            id="messageTitle"
            name="messageTitle"
            value={formData.messageTitle}
            onChange={handleChange}
            required
          >
            <option value="">Select a title</option>
            {messageTitles.map((title, index) => (
              <option key={index} value={title}>{title}</option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="message">Message *</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            placeholder="Share your inquiry, feedback, or booking details. For reservations, please specify the package, dates, and number of guests. Include any special requirements or questions about our offerings."
            minLength="10"
            rows="5"
          ></textarea>
        </div>

        <button 
          type="submit" 
          className="submit-button"
          disabled={status === 'Sending message...'}
        >
          {status === 'Sending message...' ? 'Sending...' : 'Submit'}
        </button>
      </form>
      <ChatBot />
    </div>
  );
};

export default Contact;
